@charset "utf-8";

$font-family-base: Helvetica Neue, Arial, sans-serif;
$border-radius: 10px;
$dark: #101010;

@import "~bootstrap/scss/bootstrap";

.disabled {
  pointer-events: none;
}

[disabled] {
  cursor: not-allowed;
}

.btn-reset {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  cursor: pointer;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

.font-size-sm {
  font-size: .8rem;
}
